import jsPDF from 'jspdf'
import 'jspdf-autotable'

import { DYES } from './chartConfig'
import { round } from './number'
import { objectHas } from './object'

const header = ['Well', 'Detector', 'Reporter Dye' /* Cycle#... */]

// well -> chamber + 4 * test-index
// detector -> "PCR" + chamber# + channel#
const getRow = (index, curve) => [
  curve.chamber + 4 * index,
  `PCR${curve.chamber}${curve.channel}`,
  DYES[curve.channel - 1],
  ...curve.data.map(({ y }) => round(y)),
]

const csvToDataString = (csv, info, delimiter) => {
  return `data:text/csv;charset=utf-8,
   ${new Date().toISOString()}\nElectronic Signatures\nNo Associated Electronic Signatures\n\n${csv
    .map((row) => row.join(delimiter))
    .join('\n')}`
}

export const portfolioToCsv = (tests, { name: portfolioName, testRecords }, projectInfo, delimiter = ',') => {
  const csv = []
  let index = 0
  let minCycles
  tests.forEach((test) => {
    if (test?.channels?.length) {
      const testRecord = testRecords.find((t) => t.UUID === test.UUID)
      test.channels.forEach((c) => {
        // channels starts from 1 - subtract one to make it index alike
        const testCurve = testRecord.curves[c - 1]
        csv.push(getRow(index, testCurve))
        // Update min cycle count - use smallest number of cycles in all the curves
        if (minCycles === undefined || minCycles > testCurve.data.length) minCycles = testCurve.data.length
      })
      index++
    }
  })

  // Prepend header to csv array and return it as a string
  csv.unshift([...header, ...[...Array(minCycles).keys()].map((c) => `Cycle ${c + 1}`)])
  // Not returning immediately to prevent logger from firing when csvToDataString fails
  const out = csvToDataString(csv, projectInfo, delimiter)
  window.logger(`exporting ABI format for portfolio ${portfolioName}`)
  return out
}

const getAutoQuantData = (data, columns, preserveData) => {
  const firstRow = data[0]
  const validColumns = columns
    .filter(({ field, calcFnc }) => calcFnc || objectHas(firstRow, field))
    .map(({ label, field, calcFnc }) => [label, field, calcFnc])
  const mappedData = data.map((row) => {
    return validColumns.map(([, field, calcFnc]) => {
      const value = calcFnc && !(preserveData && objectHas(row, field)) ? calcFnc(row) : row[field]
      if (typeof value === 'string') return value.replace(/\r?\n|\r/, ' ')
      return value
    })
  })
  mappedData.unshift([validColumns.map(([label]) => label)])
  return mappedData
}

export const autoQuantToCsv = (data, columns, projectInfo, delimiter = ',') => {
  const out = csvToDataString(getAutoQuantData(data, columns, true), projectInfo, delimiter)
  return out
}

export const autoQuantToPdf = (data, columns, portfolioName, projectInfo, exportFileName) => {
  if (jsPDF !== null) {
    const [head, ...body] = getAutoQuantData(data, columns)

    const content = {
      startY: 50,
      head,
      body,
    }

    const unit = 'pt'
    const size = 'A4'
    const orientation = 'landscape'

    const doc = new jsPDF(orientation, unit, size)

    // Get the logo image and convert it to png
    const svgImage = document.querySelector('span img[alt="Home"]')
    const canvas = document.createElement('canvas')
    document.body.appendChild(canvas)
    const ctx = canvas.getContext('2d')
    canvas.width = 32
    canvas.height = 32
    // Convert white to blue color
    ctx.filter = 'invert(1) invert(21%) sepia(95%) saturate(2596%) hue-rotate(187deg) brightness(102%) contrast(98%)'
    ctx.drawImage(svgImage, 0, 0, 32, 32)
    const imgData = canvas.toDataURL('image/png')
    canvas.remove()
    doc.addImage(imgData, 'PNG', 40, 10, 32, 32)

    doc.setFontSize(15)
    doc.text(portfolioName, 80, 30)

    doc.autoTable(content)

    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()

    doc.setFontSize(8)
    doc.text(`${projectInfo.name} - v-${projectInfo.version}`, pageWidth - 40, pageHeight - 10, { align: 'right' })
    doc.save(exportFileName)
  }
}

export const download = (data, fileName) => {
  // Try to prevent popup blockers from detecting download as new tab
  fetch(data)
    .then((response) => response.blob())
    .then((blob) => {
      const link = window.document.createElement('a')
      const mimeType = data.split(';base64').shift().slice(5)
      link.href = window.URL.createObjectURL(blob, { type: mimeType })
      link.download = fileName
      window.document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      link.remove()
    })
}

const exportSvgIframe = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.6.0/Chart.min.js"></script>
    <script src="https://rawgit.com/gliffy/canvas2svg/master/canvas2svg.js"></script>
  </head>
  <body>
    <script>
    C2S.prototype.getContext = function (contextId) {
      if (contextId == '2d' || contextId == '2D') {
        return this
      }
      return null
    }
    C2S.prototype.style = function () {
      return this.__canvas.style
    }
    C2S.prototype.getAttribute = function (name) {
      return this[name]
    }
    C2S.prototype.addEventListener = function (type, listener, eventListenerOptions) {
      console.log('canvas2svg.addEventListener() not implemented.')
    }
    Chart.defaults.global.animation = false
    Chart.defaults.global.elements.line.fill = false

    window.addEventListener(
      'message',
      function ({ data: config }) {
        if (config) {
          let fileName
          config = JSON.parse(config)
          if (!config.options) config.options = {}
          if (!config.type) config.type = 'line'
          if (config.fileName) {
            fileName = config.fileName
            delete config.fileName
          }
          config.options.responsive = false
          config.options.animation = false

          // canvas2svg 'mock' context
          const svgContext = C2S(config.width || 960, config.height || 480)

          // new chart on 'mock' context fails:
          const svgChart = new Chart(svgContext, config)
          const str = svgContext.getSerializedSvg(true)
          window.parent.postMessage([str, fileName], '*')
        }
      },
      false,
    )
    </script>
  </body>
</html>
`

const postMessageCallback = ({ data: [str, fileName = 'chart.svg'] }) => {
  window.removeEventListener('message', postMessageCallback)
  download('data:image/svg+xml;utf8,' + encodeURIComponent(str), fileName)
  document.getElementById('snapshot').remove()
}

export const createSvg = (config, fileName) => {
  window.addEventListener('message', postMessageCallback, false)
  const iframe = document.createElement('iframe')
  iframe.id = 'snapshot'
  iframe.srcdoc = exportSvgIframe
  iframe.width = 0
  iframe.height = 0
  iframe.style.position = 'absolute'
  iframe.style.left = '-1000px'
  iframe.frameborder = 0
  document.body.appendChild(iframe)
  iframe.onload = () => {
    const clonedConfig = { fileName, options: config.options, data: { datasets: [], labels: config.data.labels } }
    config.data.datasets.forEach(({ _meta, ...dataset }) => {
      clonedConfig.data.datasets.push(dataset)
    })
    const str = JSON.stringify(clonedConfig)
    iframe.contentWindow.postMessage(str, '*')
  }
}
