import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import FileCopy from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import ShowGraphIcon from '@material-ui/icons/Timeline'
import Tooltip from '@material-ui/core/Tooltip'

import { formatDate } from '../utils/formatDate'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'
import { getPortfolios, getPortfoliosQuery } from '../store/selectors/portfolios.selectors'
import { services } from '../store/feathers'
import { showModal } from '../store/modal'

const getNextName = (records, name) => {
  // First escape any special character in name string to prevent it from being parsed as regex command
  const escapedName = name.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&')
  const regex = new RegExp(`^${escapedName} \\(copy (\\d+)\\)$`)
  let maxNum = 0

  records.forEach((rec) => {
    const found = rec.name.match(regex)
    if (found && Number(found[1]) > maxNum) {
      maxNum = Number(found[1])
    }
  })
  return `${name} (copy ${maxNum + 1})`
}

const PortfoliosActions = (props) => {
  const { record } = props
  const hasAdminRights = useSelector(isAdmin)
  const email = useSelector(getEmail)
  return (
    <>
      <Tooltip title="Show Curve">
        <span>
          <IconButton
            aria-label="Show Curve"
            disabled={!(hasAdminRights || email === record.creator || record.isPublic)}
            component={Link}
            to={{ pathname: `/portfolios/${record._id}/show-curve`, returnTo: '/portfolios' }}
          >
            <ShowGraphIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="View Portfolio">
        <span>
          <IconButton
            aria-label="View Portfolio"
            disabled={!(hasAdminRights || email === record.creator || record.isPublic)}
            component={Link}
            to={`/portfolios/${record._id}`}
          >
            <AssignmentIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const PortfoliosEditActions = (props) => {
  const { record } = props
  const dispatch = useDispatch()
  const portfolios = useSelector(getPortfolios)
  const portfolioQuery = useSelector(getPortfoliosQuery)

  const onDelete = () => {
    dispatch(
      showModal({
        title: 'Delete',
        content: (
          <>
            Are you sure you want to delete {record.name ? <b>{record.name} </b> : 'selected'}
            portfolio?
          </>
        ),
        confirmLabel: 'Delete',
        onConfirm: () => {
          dispatch(services.portfolios.remove(record._id)).then(() => {
            // On record delete reset the pagination (skip) to 0
            dispatch(services.portfolios.find({ query: { ...portfolioQuery.query, $skip: 0 } }))
          })
        },
      }),
    )
  }

  const onClone = () => {
    const { createdAt, _id, updatedAt, name, ...data } = record
    const clonedName = getNextName(portfolios, name)

    dispatch(
      showModal({
        title: 'Clone Portfolio',
        content: (
          <>
            Are you sure you want to clone {record.name ? <b>{record.name} </b> : 'selected'}
            portfolio?
          </>
        ),
        confirmLabel: 'Clone',
        onConfirm: () => {
          dispatch(services.portfolios.create({ ...data, name: clonedName })).then(() => {
            dispatch(services.portfolios.find(portfolioQuery))
          })
        },
      }),
    )
  }

  return (
    <>
      <Tooltip title="Clone Portfolio">
        <IconButton aria-label="Clone Portfolio" onClick={onClone}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Portfolio">
        <IconButton aria-label="Delete Portfolio" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

/**
 * Get the Analyte information based on the assayShortName, chamber
 * and channel in the record.
 * 
 * @param {object} record Record containing the row data. 
 * @returns Analyte name as a string.
 */
export const getAnalyteName = (record) => {
  let assayName = record.assayShortName;

  if(assayName.includes("RVP4")) {
    return getAnalyteNameRvp4(record.chamber, record.channel);
  }
  else if(assayName.includes("RVP11"))
  {
    return getAnalyteNameRvp11(record.chamber, record.channel);
  }
  else if(assayName.includes("HSV Full"))
  {
    return getAnalyteNameHsvExperimental(record.chamber, record.channel);
  }
  else if(assayName.includes("HSV"))
  {
    return getAnalyteNameHsv(record.chamber, record.channel);
  }
  else if(assayName.includes("STI"))
  {
    return getAnalyteNameSti(record.chamber, record.channel);
  }
  else if(assayName.includes("Phary"))
  {
    return getAnalyteNamePhary(record.chamber, record.channel);
  }
  else if(assayName.includes("GI"))
  {
    return getAnalyteNameGI(record.chamber, record.channel);
  }
  else if(assayName.includes("p606-rvp-v"))
  {
    return getAnalyteNameStiExperimental(record.chamber, record.channel);
  }
  else if(assayName.includes("p614-hvt"))
  {
    return getAnalyteNameHsvExperimental(record.chamber, record.channel);
  }
  else {
    //return getPanelNameRvp11(record.chamber, record.channel);
    return ""
  }

  return ""
}

/**
 * Convert the Chamber and Channel to an RVP4 analyte.
 * @param {int} chamber Chamber number.
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameRvp4 = (chamber, channel) => {
  var dictRvp4 = {
    "1_1": "Flu A",
    "1_2": "Flu B",
    "1_3": "RSV",
    "1_4": "PRC Chbr1",
    "2_1": "",
    "2_2": "",
    "2_3": "",
    "2_4": "",
    "3_1": "",
    "3_2": "",
    "3_3": "",
    "3_4": "",
    "4_1": "CoV2",
    "4_2": "",
    "4_3": "",
    "4_4": "PRC Chbr2"
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictRvp4[key];
}

/**
 * Convert the Chamber and Channel to a RVP11 analyte.
 * @param {int} chamber Chamber number. 
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameRvp11 = (chamber, channel) => {
  var dictRvp11 = {
    "1_1": "Flu A",
    "1_2": "Flu B",
    "1_3": "RSV",
    "1_4": "PRC Chbr1",
    "2_1": "PiV 1+3",
    "2_2": "",
    "2_3": "Seasonal CoV",
    "2_4": "PRC Chbr2",
    "3_1": "hMPV",
    "3_2": "Entero",
    "3_3": "Rhino",
    "3_4": "PRC Chbr3",
    "4_1": "CoV2",
    "4_2": "PiV 2+4",
    "4_3": "Adeno",
    "4_4": "PRC Chbr4"
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictRvp11[key];
}


/**
 * Convert the Chamber and Channel to an HSV analyte.
 * @param {int} chamber Chamber number. 
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameHsv = (chamber, channel) => {
  var dictHsv = {
    "1_1": "HSV 1",
    "1_2": "HSV 2",
    "1_3": "PRC Chbr1",
    "1_4": "",
    "2_1": "VZV",
    "2_2": "TP",
    "2_3": "PRC Chbr2",
    "2_4": "",
    "3_1": "",
    "3_2": "",
    "3_3": "",
    "3_4": "",
    "4_1": "",
    "4_2": "",
    "4_3": "",
    "4_4": ""
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictHsv[key];
}


/**
 * Convert the Chamber and Channel to an STI analyte.
 * @param {int} chamber Chamber number. 
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameSti = (chamber, channel) => {
  var dictSTI = {
    "1_1": "CT",
    "1_2": "TV",
    "1_3": "",
    "1_4": "PRC Chbr1",
    "2_1": "NG",
    "2_2": "MG",
    "2_3": "",
    "2_4": "PRC Chbr2",
    "3_1": "",
    "3_2": "",
    "3_3": "",
    "3_4": "",
    "4_1": "",
    "4_2": "",
    "4_3": "",
    "4_4": ""
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictSTI[key];
}

/**
 * Convert the Chamber and Channel to an STI Experimental analyte.
 * @param {int} chamber Chamber number.
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameStiExperimental = (chamber, channel) => {
  var dictSTI = {
    "1_1": "CT",
    "1_2": "TV",
    "1_3": "",
    "1_4": "PRC Chbr1",
    "2_1": "NG",
    "2_2": "MG",
    "2_3": "",
    "2_4": "PRC Chbr2",
    "3_1": "",
    "3_2": "SAC",
    "3_3": "",
    "3_4": "PRC Chbr3",
    "4_1": "",
    "4_2": "",
    "4_3": "",
    "4_4": ""
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictSTI[key];
}


/**
 * Convert the Chamber and Channel to an HSV Experimental analyte.
 * @param {int} chamber Chamber number.
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
export const getAnalyteNameHsvExperimental = (chamber, channel) => {
  var dictSTI = {
    "1_1": "HSV1",
    "1_2": "HSV2",
    "1_3": "",
    "1_4": "PRC Chbr1",
    "2_1": "VZV",
    "2_2": "TP",
    "2_3": "",
    "2_4": "PRC Chbr2",
    "3_1": "",
    "3_2": "SAC",
    "3_3": "",
    "3_4": "PRC Chbr3",
    "4_1": "",
    "4_2": "",
    "4_3": "",
    "4_4": ""
  }

  let key = chamber.toString() + "_" + channel.toString();
  return dictSTI[key];
}



/**
 * Convert the Chamber and Channel to an Pharyngitis analyte.
 * @param {int} chamber Chamber number. 
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
   export const getAnalyteNamePhary = (chamber, channel) => {
    var dictPhary = {
      "1_1": "Strep A",
      "1_2": "",
      "1_3": "Strep C/G",
      "1_4": "PRC Chbr1",
      "2_1": "Arcano",
      "2_2": "",
      "2_3": "Fuso",
      "2_4": "PRC Chbr2",
      "3_1": "",
      "3_2": "",
      "3_3": "",
      "3_4": "",
      "4_1": "",
      "4_2": "",
      "4_3": "",
      "4_4": ""
    }
  
    let key = chamber.toString() + "_" + channel.toString();
    return dictPhary[key];
  }

  /**
 * Convert the Chamber and Channel to an GI analyte.
 * @param {int} chamber Chamber number. 
 * @param {int} channel Channel number.
 * @returns Analyte name as a string.
 */
   export const getAnalyteNameGI = (chamber, channel) => {
    var dictGI = {
      "1_1": "Campy",
      "1_2": "EITC/EIEC",
      "1_3": "Salmonella",
      "1_4": "PRC Chbr1",
      "2_1": "Vibrio",
      "2_2": "STEC",
      "2_3": "Yersinia",
      "2_4": "PRC Chbr2",
      "3_1": "Sapo",
      "3_2": "Rota",
      "3_3": "C. diff",
      "3_4": "PRC Chbr3",
      "4_1": "Noro",
      "4_2": "Adeno(F40/41)",
      "4_3": "Astro",
      "4_4": "PRC Chbr4"
    }
  
    let key = chamber.toString() + "_" + channel.toString();
    return dictGI[key];
  }

/**
 * Columns used in Portfolios.js table.
 */
export const portfoliosColumns = [
  {
    label: '',
    field: 'actions-link',
    width: 1,
    nowrap: true,
    render: (record) => {
      return <PortfoliosActions record={record} />
    },
  },
  {
    label: 'Name',
    field: 'name',
    hasFilter: true,
    isSortable: true,
    render: (record) => {
      return (
        <Link to={{ pathname: `/portfolios/${record._id}/show-curve`, returnTo: '/portfolios' }}>{record.name}</Link>
      )
    },
  },
  { label: 'Creator', field: 'creator', hasFilter: true, isSortable: true },
  {
    label: 'Description',
    field: 'description',
    hasFilter: true,
    isSortable: true,
    width: 300,
    render: (record) => {
      return <div style={{ whiteSpace: 'break-spaces' }}>{record.description}</div>
    },
  },
  {
    label: 'Created',
    field: 'createdAt',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    render: (record) => {
      return formatDate(record.createdAt)
    },
  },
  {
    label: 'Updated',
    field: 'updatedAt',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    render: (record) => {
      return formatDate(record.updatedAt)
    },
  },
  {
    label: 'Public',
    field: 'isPublic',
    hasFilter: true,
    isSortable: true,
    type: 'boolean',
    render: (record) => {
      return record.isPublic ? <CheckIcon /> : null
    },
  },
  {
    label: '',
    field: 'actions',
    width: 1,
    nowrap: true,
    render: (record) => {
      return <PortfoliosEditActions record={record} />
    },
  },
]

/**
 * Columns used in Portfolio.js table.
 * @param {*} prepend 
 * @param {*} append 
 * @returns 
 */
export const portfolioColumns = (prepend, append) => [
  ...(prepend ? [prepend] : []),
  {
    label: 'Date',
    field: 'date',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    nowrap: true,
    render: (record) => {
      return !record.date || !isNaN(record.date) ? 'No Date Stamp' : formatDate(record.date)
    },
  },
  {
    label: 'Sample Info',
    field: 'meta.sampleInfo1Value',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.sampleInfo1Value || 'undefined',
  },
  {
    label: 'Operator',
    field: 'meta.operator',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.operator || 'undefined',
  },
  {
    label: 'Instrument ID',
    field: 'instrumentId',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.instrumentId,
  },
  {
    label: 'Cartridge S/N',
    field: 'meta.cartridgeSerialNumber',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.cartridgeSerialNumber,
  },
  {
    label: 'Assay Name',
    field: 'meta.assayShortName',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.assayShortName ?? '',
  },
  {
    label: 'Assay ID',
    field: 'meta.assayId',
    type: 'number',
    hasFilter: true,
    isSortable: true,
    calcFnc: (record) =>
      record.meta.assayId
        ? record.meta.assayId + (record.meta.assayRevision ? parseFloat(`0.${record.meta.assayRevision}`) : 0)
        : 'Unknown',
    render: (record) =>
      record.meta.assayId
        ? `${record.meta.assayId}${record.meta.assayRevision ? `.${record.meta.assayRevision}` : ''}`
        : 'Unknown',
  },
  {
    label: 'Annotation',
    field: 'annotation',
    type: 'string',
    hasFilter: true,
    isSortable: true,
  },
  {
    label: '',
    field: 'annotation-action',
    cellStyle: { width: '70px' },
    headerStyle: { width: '70px' },
  },
  ...(append ? [append] : []),
]

/**
 * Columns used in AddTest.js table.
 * @param {*} prepend 
 * @param {*} append 
 * @returns 
 */
export const testsColumns = (prepend, append) => [
  ...(prepend ? [prepend] : []),
  {
    label: 'Date',
    field: 'date',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    nowrap: true,
    render: (record) => {
      return !record.date || !isNaN(record.date) ? 'No Date Stamp' : formatDate(record.date)
    },
  },
  {
    label: 'Sample Info',
    field: 'meta.sampleInfo1Value',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.sampleInfo1Value || 'undefined',
  },
  {
    label: 'Operator',
    field: 'meta.operator',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.operator || 'undefined',
  },
  {
    label: 'Instrument ID',
    field: 'instrumentId',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.instrumentId,
  },
  {
    label: 'Cartridge S/N',
    field: 'meta.cartridgeSerialNumber',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.cartridgeSerialNumber,
  },
  {
    label: 'Assay Name',
    field: 'meta.assay.assayShortName',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.assay?.assayShortName ?? '',
  },
  {
    label: 'Assay ID',
    field: 'meta.assayId',
    type: 'number',
    hasFilter: true,
    isSortable: true,
    calcFnc: (record) =>
      record.meta.assayId
        ? record.meta.assay.assayId + (record.meta.assay.assayRevision ? parseFloat(`0.${record.meta.assay.assayRevision}`) : 0)
        : 'Unknown',
    render: (record) =>
      record.meta.assayId
        ? `${record.meta.assay.assayId}${record.meta.assay.assayRevision ? `.${record.meta.assay.assayRevision}` : ''}`
        : 'Unknown',
  },
  {
    label: 'Annotation',
    field: 'annotation',
    type: 'string',
    hasFilter: true,
    isSortable: true,
  },
  {
    label: '',
    field: 'annotation-action',
    cellStyle: { width: '70px' },
    headerStyle: { width: '70px' },
  },
  ...(append ? [append] : []),
]

