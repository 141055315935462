import React, { Component } from 'react'
import { connect } from 'react-redux'

import EnhancedTable from './Table'
import { services } from '../../store/feathers'
import { isEmpty } from '../../utils/object'
import { fastDeepEqual } from '../../utils/object/fastDeepEqual'

const dateTimeRegex = /([A-Z][a-z]{2} \d{2}, \d{4} 1?[0-9]:\d{2} [AP]M)/

class DataTable extends Component {
  state = { $limit: this.props.limit, $skip: this.props.skip, $sort: this.props.sort, $filter: {} } 
  
  static getDerivedStateFromProps(props, state) {
    // Load filter from the store - if set and if there are no state filters (on initialization)
    if (props.filter && Object.keys(props.filter) && state.$filter && isEmpty(state.$filter)) {
      return {
        ...state,
        $filter: props.filter,
      }
    }
    return null
  }

  onChange = (query) => {
    const update = {}
    let forceChange = false
    Object.entries(query).forEach(([property, value]) => {
      switch (property) {
        case 'search': {
          if (value) {
            if (dateTimeRegex.test(value)) {
              update.$search = value.replace(dateTimeRegex, (match, dateTime) => {
                // Reformat the date to `DDDD-MM-DD HH:MM` - remove the seconds as well as we don't render them
                return new Date(Date.parse(dateTime)).toISOString().slice(0, 16).replace('T', ' ')
              })
            } else {
              update.$search = value
            }
          } else {
            update.$search = undefined
          }
          break
        }
        case 'filter': {
          if (value && !isEmpty(value)) {
            Object.assign(update, { $filter: value })
          } else {
            update.$filter = undefined
          }
          // Force the call even if the filter is empty as it's used to clear previous filters
          forceChange = true
          break
        }
        case 'skip': {
          update.$skip = value
          break
        }
        case 'limit': {
          update.$limit = value
          break
        }
        case 'sort': {
          update.$sort = value
          break
        }
        // no default
      }
    })
    // Trigger query change only when there is an update
    if (forceChange || Object.keys(update).length) {
      if ((update.$filter && Object.keys(update.$filter).length) || update.$search) {
        // Reset pagination when filter / or search changes
        update.$skip = 0
      }
      this.setState(update)
      // Re-fetch the current service with updated query
      this.props.find({ ...this.state, ...this.props.query, ...update })
    }
  }

  constructor(props) {
    super(props)
    if (props.service ==="tests")
    {
	    var startdate = new Date();
	    startdate.setDate(startdate.getDate()-31);
	    console.log(startdate);

	    var enddate = Date();
	    console.log(enddate);
	    this.state.$filter.date= {};
            let date= {
              startDate: startdate,
              endDate: enddate
            };
            this.state.$filter.date = date
    }

    props.find({
      ...this.state,
      ...(props?.filter && typeof props.filter === 'object' ? { $filter: props.filter } : {}),
    })
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!fastDeepEqual(prevProps.query, this.props.query)) {
  //     this.props.find({ ...this.state, ...this.props.query })
  //   }
  // }

  shouldComponentUpdate(nextProps, nextState) {
    // Re-send the request when query changes
    if (!fastDeepEqual(nextProps.query, this.props.query)) {
      this.props.find({ ...this.state, ...nextProps.query })
    }
    // Re-render only when fetchedAt changes (new data has arrived)
    // return nextProps.fetchedAt !== this.props.fetchedAt || !fastDeepEqual(nextProps.query, this.props.query)
    return nextProps.fetchedAt !== this.props.fetchedAt
  }

  render() {
    return <EnhancedTable {...this.props} onChange={this.onChange} />
  }
}

// const mapStateToProps = function (state, { service }) {
//   const serviceState = state[service]
//   // Make sure all the properties have default values if not present
//   const serviceData = Object.assign({}, { limit: 10, skip: 0, sort: { ...state.sort } }, serviceState.find)

//   return {
//     fetchedAt: serviceState.fetchedAt,
//     idField: serviceState.idField,
//     ...serviceData,
//   }
// }

const mapStateToProps = function (state, { service, persistFilters = true }) {
  const { find: data, filter, ...serviceState } = state[service]

  return {
    ...serviceState,
    ...(persistFilters ? { filter } : {}),
    // Make sure we provide an array in case data is null
    data: data || [],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    find: (query) => {
      // Normalize $filter number types
      if (query.$filter && !isEmpty(query.$filter)) {
        const { $filter, ...queryObj } = query
        queryObj.$filter = Object.entries($filter).reduce((acc, [key, val]) => {
          const columnDef = ownProps.columns.find((c) => c.field === key)
          if (columnDef && columnDef.type === 'number') val = Number(val)
          acc[key] = val
          return acc
        }, {})

        dispatch(services[ownProps.service].find({ query: queryObj }))
      } else {
        dispatch(services[ownProps.service].find({ query }))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable)
