import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import UserMenu from './UserMenu'
import AppLogo from '../assets/applogo.svg'
import { isConnected } from '../store/socket/socket.selectors'
import { isAuthenticated } from '../store/selectors/auth.selectors'
import { getProjectInfo } from '../store/selectors/project.selectors'

const useStyles = makeStyles((theme) => ({
  title: {
    marginRight: theme.spacing(),
  },
  headerIcon: {
    color: theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
  },
  projectInfo: {
    fontStyle: 'italic',
    flexGrow: 1,
  },
}))

export default function Header() {
  const classes = useStyles()
  const connected = useSelector(isConnected)
  const loggedIn = useSelector(isAuthenticated)
  const projectInfo = useSelector(getProjectInfo)

  return (
    <AppBar position="static">
      <Toolbar>
        <Link to="/portfolios">
          <IconButton edge="start" className={classes.headerIcon} aria-label="home">
            <img src={AppLogo} width="32" height="32" alt="Home" />
          </IconButton>
        </Link>
        <Typography variant="h6" className={classes.title}>
          Quidel Savanna PCR Data Portal - Dev
        </Typography>
        {loggedIn && projectInfo ? (
          <>
            <Typography variant="overline" className={classes.projectInfo}>
              {projectInfo?.version} {projectInfo?.gitCount}
            </Typography>
            <UserMenu />
          </>
        ) : null}
        {connected ? null : (
          <Tooltip title="API Server Disconnected" aria-label="connected">
            <CloudOffIcon />
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  )
}
